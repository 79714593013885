/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled } from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Stat from "../components/stat"
import Testimonial from "../components/sections/testimonial"
import PracticeAreas from "../components/sections/practiceAreas"
import BusinessIcon from "../images/icons/business.inline.svg"
import RealEstateIcon from "../images/icons/realestate.inline.svg"
import EstateIcon from "../images/icons/estate.inline.svg"
import FamilyIcon from "../images/icons/family.inline.svg"
import CriminalIcon from "../images/icons/criminal.inline.svg"
import IntellectualIcon from "../images/icons/intellectual.inline.svg"
import Quote from "../images/icons/quote.inline.svg"

const Icons = {
  'business-law': BusinessIcon,
  'real-estate-law': RealEstateIcon,
  'estate-trust-law': EstateIcon,
  'family-law': FamilyIcon,
  'criminal-law': CriminalIcon,
  'intellectual-property-law': IntellectualIcon
}

const IndexPage = ({ data }) => {
  const { site: { siteMetadata: { title } }, allFile, allTestimonial, allService } = data
  const heroImage = allFile.nodes.find(({ name }) => name === "hero-image")
  const officeImage = allFile.nodes.find(({ name }) => name === "office-white")

  return (
    <Layout>
      <SEO title={`Attorneys At Law`} />

      <Flex
        sx={{
          backgroundImage: `url("${heroImage.publicURL}")`,
          backgroundSize: 'cover',
          height: '90vh',
          alignItems: 'center'
        }}>
        <Flex
          sx={{
            bg: theme => theme.colors.darkBlue,
            flexDirection: 'column',
            justifyContent: 'center',
            height: 'full',
            width: '180px'
          }}>
          {allService.nodes.map(service => {
            const ServiceIcon = Icons[service.slug] 
            return (
              <Link
                key={service.id}
                to={`/${service.slug}`}
                sx={{
                  display: 'block',
                  px: 2,
                  py: 3,
                  mb: 2,
                  textAlign: 'center',
                  '&:hover': {
                    bg: 'primary'
                  }
                }}>
                <ServiceIcon
                  sx={{
                    width: '50px'
                  }} />
                <Styled.h6
                  sx={{
                    mt: 2,
                    fontWeight: 300,
                    color: theme => theme.colors.secondary
                  }}>
                  {service.name}
                </Styled.h6>
              </Link>
            )
          })}

        </Flex>
        <Box>
          <Styled.h1
            sx={{
              px: 4,
              py: 48,
              ml: 5,
              bg: `rgba(48,55,63,0.85)`,
              borderRadius: '0 0 50px 0',
              color: 'white',
              textShadow: theme => `3px 1px 0 ${theme.colors.darkBlue}`
            }}>
            Your Legal Team<br />
            We're on Your Side
          </Styled.h1>
        </Box>
      </Flex>

      <PracticeAreas services={allService.nodes} />

      <section
        sx={{
          py: 6,
          backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0.35) 0%, rgba(255,255,255,0.35) 100%), url("${officeImage.publicURL}")`,
          backgroundSize: 'cover',
        }}>
        <Container>
          <Flex
            sx={{
              justifyContent: 'center',
              mb: 5,
              textAlign: 'center'
            }}>
            <Box>
              <Styled.h1>
                Our Commitment
            </Styled.h1>
              <Styled.h6
                sx={{
                  fontWeight: '300'
                }}>
                We are committed to transparency and offering the best client experience
            </Styled.h6>
            </Box>
          </Flex>
          <Flex
            sx={{
              justifyContent: 'center'
            }}>
            <Flex
              sx={{
                width: ['full', '2/3']
              }}>
              <Box
                sx={{
                  bg: 'rgba(255,255,255,0.7)',
                  width: ['full', '2/3'],
                  p: 4
                }}>
                <p sx={{
                  mb: 4
                }}>At Fargo Law our attorneys specialize in many area practices including: <strong>Business Law</strong> (Corporate Law & Litigation), <strong>Real Estate Law</strong>, <strong>Estate & Trust Planning</strong>, <strong>Family Law</strong> and <strong>Criminal Defense</strong>. Combined, our lawyers have hundreds of years of experience and thousands of hours in litigation.  Our attorneys are licensed in North Dakota, South Dakota and Minnesota and represent clients all over the region. We are committed to personalizing our approach to your needs and value client service over all.</p>
                <Button>
                  Free Consultation
                </Button>
              </Box>
              <Box
                sx={{
                  bg: 'primary',
                  position: 'relative',
                  py: 3,
                  px: 4,
                  width: ['full', '1/3'],
                  borderRadius: '0 0 50px 0',
                  color: 'secondary'
                }}>
                <Quote
                  sx={{
                    position: 'absolute',
                    top: 3,
                    left: 3,
                    width: '1/2',
                    path: {
                      fill: 'rgba(255,255,255,0.2)',
                    }
                  }} />
                <p
                  sx={{
                    variant: 'text.script',
                    lineHeight: 'relaxed',
                    fontSize: 2
                  }}>
                  I have complete trust in {title} and their team when it comes to my business and succession planning. There's only one firm I trust and recommend and that's {title}<br />
                  - Scott
                </p>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </section>

      <Container
        sx={{
          py: 6
        }}>
        <Flex
          sx={{
            justifyContent: 'center'
          }}>
          <Box>
            <Styled.h1
              sx={{
                mt: 0
              }}>
              Exceptional Experience
            </Styled.h1>
            <Styled.h6
              sx={{
                fontWeight: '300',
                textAlign: 'center'
              }}>
              Fargo Law has a over <strong>100 years</strong> of combined legal experience
            </Styled.h6>
          </Box>
        </Flex>
        <Flex
          sx={{
            mt: 5
          }}>
          <Box
            sx={{
              mx: 3,
              width: ['1/2', '1/4']
            }}>
            <Stat>
              <p
                sx={{
                  fontSize: 7,
                  m: 0,
                }}>100</p>
              <p
                sx={{
                  fontSize: 2,
                  m: 0,
                }}>Years Experience</p>
            </Stat>
          </Box>
          <Box
            sx={{
              mx: 3,
              width: ['1/2', '1/4']
            }}>
            <Stat
              filename="lady-justice">
              <p
                sx={{
                  fontSize: 7,
                  m: 0,
                  color: 'secondary'
                }}>900</p>
              <p
                sx={{
                  fontSize: 2,
                  m: 0,
                  color: 'secondary'
                }}>Court Cases</p>
            </Stat>
          </Box>
          <Box
            sx={{
              mx: 3,
              width: ['1/2', '1/4']
            }}>
            <Stat>
              <p
                sx={{
                  fontSize: 7,
                  m: 0,
                }}>15</p>
              <p
                sx={{
                  fontSize: 2,
                  m: 0,
                }}>Attorneys at Law</p>
            </Stat>
          </Box>
          <Box
            sx={{
              mx: 3,
              width: ['1/2', '1/4']
            }}>
            <Stat
              filename="court-gable">
              <p
                sx={{
                  fontSize: 7,
                  m: 0,
                  color: 'secondary'
                }}>3</p>
              <p
                sx={{
                  fontSize: 2,
                  m: 0,
                  color: 'secondary'
                }}>Office Locations</p>
            </Stat>
          </Box>
        </Flex>
      </Container>

      <Testimonial testimonial={allTestimonial.nodes[0]} />

    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {name: {in: ["hero-image", "office-white"]}}) {
      nodes {
        name
        publicURL
      }
    }
    allTestimonial{
      nodes {
        image{
          publicURL
        }
        name
        quote
        title
        id
      }
    }
    allService{
      nodes{
        id
        icon{
          publicURL
        }
        slug
        name
        description
      }
    }
  }
`

export default IndexPage
