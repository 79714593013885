/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import QuoteIcon from "../../images/icons/quote.inline.svg"

const Testimonial = ({ testimonial }) => {
  const { file: { publicURL: bgURL } } = useStaticQuery(graphql`
    {
      file(name: { eq: "lawyer-couple" } ){
        publicURL
      }
    }
  `)
  return (
    <section
      sx={{
        backgroundImage: `linear-gradient(to top, rgba(48,55,63,0.75) 0%, rgba(48,55,63,0.75) 100%), url("${bgURL}")`,
        backgroundSize: 'cover',
        py: 6
      }}>
      <Container>
        <Flex>
          <Flex
            sx={{
              width: ['full', '1/2'],
              flexDirection: 'column'
            }}>
            <Flex
              sx={{
                alignItems: 'center'
              }}>
              <Box
                sx={{
                  width: '1/6',
                  mr: 4
                }}>
                <hr
                  sx={{
                    borderColor: 'secondary'
                  }}
                />
              </Box>
              <Box>
                <Styled.h6 sx={{
                  color: 'white',
                  mb: 0
                }}>Testimonial</Styled.h6>
              </Box>
            </Flex>
            <Styled.h1
              sx={{
                color: 'white'
              }}>
              We have been trusted to represent clients in over 1,000 cases.
              </Styled.h1>
          </Flex>
          <Flex
            sx={{
              width: ['full', '1/2']
            }}>
            <Box
              sx={{
                width: '1/3',
              }}>
              <QuoteIcon
                sx={{
                  fill: 'secondary',
                  width: '100%',
                  mt: '-125px',
                }} />
            </Box>
            <Flex
              sx={{
                ml: 4,
                flexDirection: 'column'
              }}>
              <Box>
                <p sx={{
                  color: 'white',
                }}>
                  { testimonial.quote }
                </p>
              </Box>
              <Flex
                sx={{
                  mt: 3,
                  alignItems: 'center'
                }}>
                <img 
                  sx={{
                    borderRadius: 'full',
                    width: '95px',
                    mr: 3
                  }}
                  src={testimonial.image.publicURL} alt="testimonial" />
                  { ' ' }
                  <Flex
                    sx={{
                      flexDirection: "column"
                    }}>
                    <Styled.h6
                      sx={{
                        textTransform: "uppercase",
                        color: "white",
                        mb: 0
                      }}>{ testimonial.name }</Styled.h6>
                    <span
                      sx={{
                        fontSize: 0,
                        color: "white"
                      }}>
                        { testimonial.title }
                    </span>
                  </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </section>
  )
}

export default Testimonial