/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

const Stat = ({ children, filename = 'court-house' }) => {
  const data = useStaticQuery(graphql`
    {
      allFile{
        nodes{
          name
          publicURL
        }
      }
    }
  `)
  const image = data.allFile.nodes.find(({ name }) => name === filename)
  return (
    <div
      sx={{
        backgroundImage: `url("${image.publicURL}")`,
        backgroundSize: 'cover',
        borderRadius: '0 0 50px 0',
        p: 4
      }}>
      <div
        sx={{
          bg: 'rgba(255,255,255,0.9)',
          p: 4,
          borderRadius: '0 0 50px 0',
          textAlign: 'center'
        }}>
         { children } 
      </div> 
    </div>
  )
}
export default Stat